body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #1a1a1a;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* src/LandingPage.css */
.landing-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #121212; /* Very dark gray */
  color: #ffffff;
}
.policy-container {
  background-color: #121212;
}

.content {
  display: flex;
  max-width: 1200px;
  padding: 20px;
}

.app-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  align-self: center;
}

.app-logo {
  width: 100px;
  height: 100px;
  background: linear-gradient(to bottom right, black, #008080);
  border-radius: 20px;
  margin-bottom: 20px;
  align-content: center;
}

h1 {
  font-size: 2em;
  margin-bottom: 10px;
}

.download-buttons {
  display: flex;
  gap: 10px;
  margin: 20px 0;
}

.button {
  width: 160px;
  height: 80px;
  background-color: black;
  border-radius: 16px;
}

.app-logo img {
  width: 100px; /* Adjust as needed */
  height: auto;
}

.button-disabled {
  width: 160px;
  height: 80px;
  background-color: black;
  border-radius: 16px;
  opacity: 0.2;
  cursor: not-allowed;
  pointer-events: none;
}

.button.ios {
  /* background: url('./assets/images/playstore-icon.png') no-repeat center center; */
  background: url('./assets/images/app-store.png') no-repeat center center;
  background-size: contain;
  height: 60px;
  width: 220px;
}

.button.playstore {
  background: url('./assets/images/play-store.png') no-repeat center center;
  background-size: contain;
  height: 60px;
  width: 220px;
}

.phone-display {
  position: relative;
  width: 300px;
  height: 450px;
  background: url('./assets/images/ip14pm-phone-frame.png') no-repeat center
    center;
  background-size: contain;
  z-index: 1;
  color: #ffffff; /* Ensure content stands out against the dark background */
}

.phone-display img {
  position: absolute;
  width: 66%; /* Adjust to fit within the screen area */
  height: 94%; /* Adjust to fit within the screen area */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: fill;
  border-radius: 24px;
  z-index: 0;
}

.phone-display .blurred {
  filter: blur(3px);
  /* clip-path: inset(96px 0 72px 0); */
  clip-path: inset(12% 0 9% 0);
}

.legal,
.website {
  font-size: 0.75em;
  text-align: center;
  margin-top: 10px;
}

.carousel {
  position: relative;
  width: 100%;
  height: 100%; /* Adjust based on your design */
  overflow: hidden;
}

.carousel-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.carousel-image.visible {
  opacity: 1;
}

@media (min-width: 1024px) {
  .phone-display {
    position: relative;
    width: 400px;
    height: 600px;
    background: url('./assets/images/ip14pm-phone-frame.png') no-repeat center
      center;
    background-size: contain;
    z-index: 1;
    color: #ffffff; /* Ensure content stands out against the dark background */
  }

  .phone-display img {
    position: absolute;
    width: 66%; /* Adjust to fit within the screen area */
    height: 95%; /* Adjust to fit within the screen area */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: fill;
    border-radius: 40px;
    z-index: 0;
  }
}

@media (max-width: 768px) {
  .content {
    flex-direction: column;
    align-items: center;
  }

  .phone-display {
    width: 300px;
    height: 450px;
  }

  .phone-display img {
    width: 66%;
    height: 94%;
    border-radius: 26px;
  }

  .download-buttons {
    flex-direction: column;
  }
}
